import isMobile from 'is-mobile';
import '../common/bootstrap';

import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

window.addEventListener("DOMContentLoaded", (event) => {
	let mybutton 	= document.getElementsByClassName("btn-back-to-top")[0];
	let mybutton2 = document.getElementsByClassName("btn-w-menu")[0];

	mybutton.addEventListener("click", backToTop);
	mybutton2.addEventListener("click", downBelow);

	function backToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}

	function downBelow() {
		document.body.scrollTop = document.body.scrollHeight;
		document.documentElement.scrollTop = document.documentElement.scrollHeight;
	}

	if (isMobile()) {
		document.querySelector('#contentPart').scrollIntoView({behavior: 'smooth'});
	}

	// document.getElementById('toFooter').addEventListener('click', function() {
	// 	document.querySelector('#footerNav').scrollIntoView({behavior: 'smooth'});
	// });

	// document.getElementById('toFooter').addEventListener('click', function() {
	// 	document.querySelector('#footerNav').scrollIntoView({behavior: 'smooth'});
	// });
});

